import axios from "axios"

const baseURL = "https://tecnipartes-8lvni.ondigitalocean.app/api"

const getToken = () => {
    return `Bearer ${localStorage.getItem('token')}`;
  };



export const obtenerUsos = async (successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/usos/`,
    headers: {
      Authorization: getToken(),
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const obtenerUso = async (id, successCallback, errorCallback) => {
  const options = {
    method: "GET",
    url: `${baseURL}/uso/${id}/`,
    headers: {
      Authorization: getToken(),
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const crearUso = async (data, successCallback, errorCallback) => {
  const options = {
    method: "POST",
    url: `${baseURL}/uso/`,
    headers: {
     "Content-Type": "application/json",
     Authorization: getToken()
    },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const editarUso = async (
  id,
  data,
  successCallback,
  errorCallback
) => {
  const options = {
    method: "PATCH",
    url: `${baseURL}/uso/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    },
    data,
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}

export const eliminarUso = async (id, successCallback, errorCallback) => {
  const options = {
    method: "DELETE",
    url: `${baseURL}/uso/${id}/`,
    headers: {
      "Content-Type": "application/json",
      Authorization: getToken()
    },
  }
  await axios.request(options).then(successCallback).catch(errorCallback)
}
