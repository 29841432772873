import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import { obtenerProducto } from "../../utils/apiProductos"
import { obtenerCategoria } from "../../utils/apiCategoria"
import { obtenerUso } from "../../utils/apiUsos"

const Producto = () => {
  const [productoSeleccionado, setProductoSeleccionado] = useState({})
  const [imagenPrincipal, setImagenPrincipal] = useState("")
  const [categoria, setCategoria] = useState("")
  const [usos, setUsos] = useState([])

  const idProducto = useParams().id

  const idCategoria = productoSeleccionado.categoria

  useEffect(() => {
    const fetchProducto = async () => {
      await obtenerProducto(`${idProducto}`, (response) => {
        setProductoSeleccionado(response.data)
      })
    }

    fetchProducto()
  }, [idProducto])

  useEffect(() => {
    const fetchCategoria = async () => {
      if (idCategoria) {
        await obtenerCategoria(`${idCategoria}`, (response) => {
          setCategoria(response.data.nombre)
        })
      }
    }

    fetchCategoria()
  }, [productoSeleccionado, idCategoria]);

  useEffect(() => {
    const fetchUsos = async () => {
      if (Array.isArray(productoSeleccionado.usos)) {
        const idUsosArray = productoSeleccionado.usos
        const usosData = []

        for (const idUsos of idUsosArray) {
          await obtenerUso(`${idUsos}`, (response) => {
            usosData.push(response.data)
          })
        }
        setUsos(usosData)
      }
    }

    fetchUsos();
  }, [productoSeleccionado]);

  useEffect(() => {
    if (productoSeleccionado.imagenesPath && productoSeleccionado.imagenesPath[0]) {
      setImagenPrincipal(productoSeleccionado.imagenesPath[0]);
    }
  }, [productoSeleccionado]);
  
  useEffect(() => {
    console.log("imagenPricnipal", imagenPrincipal);
  }, [imagenPrincipal]);
  

  return (
    <div className="flex w-full flex-col h-full justify-start bg-zinc-50 overflow-y-auto">
      <div className="bg-white h-full mb-3 mt-10 sm:mx-10 shadow shadow-slate-200 rounded pt-6 overflow-y-auto ">
        <div className="flex flex-col sm:flex-row">
          <div className="w-1/2 pr-6">
          <div className="flex justify-start ml-10">
              <Link to="/productos">
                <button className="flex items-center bg-blue-600 hover:bg-blue-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold">
                  Atrás
                </button>
              </Link>
            </div>
            <div className="hidden md:flex flex-col md:flex-row pt-16 ">
              {productoSeleccionado.imagenesPath &&
                productoSeleccionado.imagenesPath[0] && (
                  <img
                  key={imagenPrincipal}
                  src={`https://tecnipartes-imagenes.nyc3.digitaloceanspaces.com/${imagenPrincipal}`}
                    alt=""
                    className="m-auto cursor-pointer h-2/4 w-2/4"
                  />
                )}
            </div>

            <div className="flex flex-col sm:flex-row space-y-6 space-x-6 mt-10  ">
              <div className="pl-8">
                {productoSeleccionado.imagenesPath &&
                  productoSeleccionado.imagenesPath[0] && (
                    <img
                      key={productoSeleccionado.imagenesPath[0]}
                      src={`https://tecnipartes-imagenes.nyc3.digitaloceanspaces.com/${productoSeleccionado.imagenesPath[0]}`}
                      alt=""
                      className="mx-auto w-40 cursor-pointer"
                      onClick={() =>
                        setImagenPrincipal(productoSeleccionado.imagenesPath[0])
                      }
                    />
                  )}
              </div>
              <div className="">
                {productoSeleccionado.imagenesPath &&
                  productoSeleccionado.imagenesPath.length >= 2 && (
                    <img
                      key={productoSeleccionado.imagenesPath[1]}
                      src={`https://tecnipartes-imagenes.nyc3.digitaloceanspaces.com/${productoSeleccionado.imagenesPath[1]}`}
                      alt=""
                      className="mx-auto w-40 cursor-pointer"
                      onClick={() =>
                        setImagenPrincipal(productoSeleccionado.imagenesPath[1])
                      }
                    />
                  )}
              </div>
              <div className="">
                {productoSeleccionado.imagenesPath &&
                  productoSeleccionado.imagenesPath.length >= 2 && (
                    <img
                      key={productoSeleccionado.imagenesPath[2]}
                      src={`https://tecnipartes-imagenes.nyc3.digitaloceanspaces.com/${productoSeleccionado.imagenesPath[2]}`}
                      alt=""
                      className="mx-auto w-40 cursor-pointer"
                      onClick={() =>
                        setImagenPrincipal(productoSeleccionado.imagenesPath[2])
                      }
                    />
                  )}
              </div>
            </div>
          </div>
          <div className="w-1/2">
            <h1 className="text-2xl font-extrabold text-gray-900 pb-10  pt-10 mr-10">
              {productoSeleccionado.nombre}({productoSeleccionado.referencia })
            </h1>
            <div className="relative sm:rounded-lg mr-20 ">
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Descripción: </span>
                {productoSeleccionado.descripcion}
              </div>
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Categoria: </span>
                {categoria}
              </div>
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Medidas </span>
                {productoSeleccionado.medidas}
              </div>
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Fecha de Ingreso: </span>
                {productoSeleccionado.fechaIngreso  ? new Date(productoSeleccionado.fechaIngreso)
                    .toISOString()
                    .split("T")[0]
                : ""}
              </div>
              <div className="flex">
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Costo: </span>
                ${productoSeleccionado.costo}
              </div>
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Precio: </span>
                ${productoSeleccionado.precio}
              </div>

              </div>
             
            
              <div className="pb-6 mr-10 text-justify">
                <span className="font-bold">Usos o Aplicaciones: </span>
                <table className="mt-2">
                  <thead>
                    <tr className="text-gray-700 uppercase bg-zinc-100">
                      <th scope="col" className="px-6 py-4">
                        Marca
                      </th>

                      <th scope="col" className="px-6 py-4">
                        Linea
                      </th>
                      <th scope="col" className="px-6 py-4">
                        Modelo
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {usos.map((uso, index) => {
                      return (
                        <tr key={index}>
                          <td className="px-6 py-4"> {uso.marca}</td>
                          <td className="px-6 py-4"> {uso.linea}</td>
                          <td className="px-6 py-4"> {uso.modelo}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>

         
            </div>
         
          </div>
        </div>
      </div>
    </div>
  )
}

export default Producto
