import React from "react"
import { useRef } from "react"
import { crearCategoria } from "../../utils/apiCategoria"
import { ToastContainer, toast } from "react-toastify"
import { Link, useNavigate } from "react-router-dom"

const NuevaCategoria = () => {
    
  const navigate = useNavigate()
  const form = useRef(null)

  const submitForm = async (e) => {
    e.preventDefault()

    const fd = new FormData(form.current)

    const nuevaCategoria = {}
    fd.forEach((value, key) => {
      nuevaCategoria[key] = value
    })

    await crearCategoria(
      {
        nombre: nuevaCategoria.nombre,
      },
      (response) => {
        toast.success("Categoria agregada con éxito")
        setTimeout(() => {
          navigate("/categorias")
        }, 1000)
      },
      (error) => {
        console.error(error)
        toast.error("Error creando un vehículo")
      }
    )
  }

  return (
    <div className="flex w-full flex-col justify-start bg-zinc-50">
      <div className="mx-auto w-9/12 px-36 pt-24 pb-18 bg-white mb-3 mt-10 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-2xl font-extrabold text-gray-900 pb-10">
            Crear Nueva Categoría
          </h1>
        </div>
        <h2 className="p-6 border border-solid border-b-principal bg-zinc-100">
          Información General
        </h2>
        <form ref={form} onSubmit={submitForm}>
          <div className="pt-10 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white"
              htmlFor="nombre"
            >
              Nombre
            </label>
            <input
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              type="text"
              name="nombre"
              required
            />
          </div>
          <div className="flex justify-end space-x-2 m-10">
            <Link to="/categorias">
              <button className="px-3 flex items-center bg-blue-600 hover:bg-blue-500  rounded sm:px-6 py-3 text-base  text-white font-semibold">
                Cancelar
              </button>
            </Link>
            <button
              type="submit"
              className="px-3 flex items-center bg-green-600 hover:bg-green-500  rounded sm:px-6 py-3 text-base  text-white font-semibold"
            >
              Crear Categoría
            </button>
          </div>
        </form>
        <ToastContainer></ToastContainer>
      </div>
    </div>
  )
}

export default NuevaCategoria
