import React, { useState, useEffect } from "react"
import { toast, ToastContainer } from "react-toastify"
import { useParams, useNavigate, Link } from "react-router-dom"
import { nanoid } from "nanoid"
import axios from "axios"
import { obtenerProducto, editarProducto } from "../../utils/apiProductos"
import { obtenerCategorias } from "../../utils/apiCategoria"
import { obtenerUsos } from "../../utils/apiUsos"

import InputComponent from "../../components/InputComponent"

const EditarProducto = () => {

  const getToken = () => {
    return `Bearer ${localStorage.getItem('token')}`
    
  };

  const [productoSeleccionado, setProductoSeleccionado] = useState({
    nombre: "",
    referencia: "",
    costo: "",
    precio: "",
    medidas: "",
    fechaIngreso: "",
    categoria: "",
    descripcion: "",
  });
  
  const [listaCategorias, setListaCategorias] = useState([])
  const [listaUsos, setListaUsos] = useState([])

  const [files, setFiles] = useState([])
  const [usos, setUsos] = useState([])

  const idProducto = useParams().id
  const navigate = useNavigate()

  useEffect(() => {
    const fetchProducto = async () => {
      await obtenerProducto(`${idProducto}`, (response) => {
        setProductoSeleccionado(response.data)
      })
    }

    fetchProducto()
  }, [idProducto])

  useEffect(() => {
    const fetchCategorias = async () => {
      await obtenerCategorias((response) => {
        setListaCategorias(response.data)
      })
    }

    fetchCategorias();
  }, []);

  useEffect(() => {
    const fetchUsos = async () => {
      await obtenerUsos((response) => {
        setListaUsos(response.data)
      })
    }

    fetchUsos();
  }, []);

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files).filter(
      (file) => file instanceof Blob || file instanceof File
    )

    if (selectedFiles.length > 0) {
      setFiles((prevFiles) => prevFiles.concat(selectedFiles))
    }
  }

  const eliminarImagenesNuevas = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);
  }

  const handleEliminarImagen = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1);
    setFiles(updatedFiles);

    const updatedImagenesPath = [...productoSeleccionado.imagenesPath];
    const imagenEliminada = updatedImagenesPath.splice(index, 1);

    setProductoSeleccionado({
      ...productoSeleccionado,
      imagenesPath: updatedImagenesPath,
    });

    const config = {
      headers: {
        Authorization: getToken()
      },
    }

    axios
      .put(`https://tecnipartes-8lvni.ondigitalocean.app/api/product/delete-images/${productoSeleccionado._id}`, {
        imagenesEliminar: imagenEliminada
      }, config)
      .then((response) => {
        // Manejar la respuesta del servidor si es necesario
      })
      .catch((error) => {
        // Manejar errores de solicitud si es necesario
      });
  };



  const actualizarProducto = async () => {
    const fd = new FormData()

    files.forEach((file) => {
      fd.append("imagenes", file)
    })

    fd.append("nombre", productoSeleccionado.nombre)
    fd.append("referencia", productoSeleccionado.referencia)
    fd.append("costo", productoSeleccionado.costo)
    fd.append("precio", productoSeleccionado.precio)
    fd.append("medidas", productoSeleccionado.medidas)
    fd.append("fechaIngreso", productoSeleccionado.fechaIngreso)
    
    fd.append("descripcion", productoSeleccionado.descripcion)

    if (productoSeleccionado.categoria) {
      fd.append("categoria", productoSeleccionado.categoria);
    }

    usos.forEach((uso) => {
      fd.append("usos", uso)
    })

    await editarProducto(
      idProducto,
      fd,
      (response) => {
        console.log(response.data)
        toast.success("Producto modificado con éxito")
        setTimeout(() => {
          navigate("/productos")
        }, 1000)

      },
      (error) => {
        toast.error("Error modificando el Producto")
        console.error(error)
      },
     
    )
  }

  return (
    <div className="flex w-full flex-col h-full justify-start bg-zinc-50 overflow-y-auto">
      <div className="mx-auto w-10/12 px-28 pt-24 pb-18 bg-white mb-3 mt-10 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-2xl font-extrabold text-gray-900 pb-10">
            Editar Producto
          </h1>
        </div>
        <div>
          <InputComponent
            label="Nombre"
            htmlfor="nombre"
            type="text"
            name="nombre"
            value={productoSeleccionado.nombre}
            onChange={(e) =>
              setProductoSeleccionado({
                ...productoSeleccionado,
                nombre: e.target.value,
              })
            }
          />
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <InputComponent
            label="Referencia"
            htmlfor="referencia"
            type="text"
            name="referencia"
            value={productoSeleccionado.referencia}
            onChange={(e) =>
              setProductoSeleccionado({
                ...productoSeleccionado,
                referencia: e.target.value,
              })
            }
          />
           <InputComponent
            label="Fecha de Ingreso"
            htmlfor="fechaIngreso"
            type="date"
            name="fechaIngreso"
            value={
              productoSeleccionado.fechaIngreso
                ? new Date(productoSeleccionado.fechaIngreso)
                    .toISOString()
                    .split("T")[0]
                : ""
            }
            onChange={(e) =>
              setProductoSeleccionado({
                ...productoSeleccionado,
                fechaIngreso: new Date(e.target.value).toISOString(),
              })
            }
          />
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
          <InputComponent
            label="Costo"
            htmlfor="costo"
            type="number"
            name="costo"
            value={productoSeleccionado.costo}
            onChange={(e) =>
              setProductoSeleccionado({
                ...productoSeleccionado,
                costo: e.target.value,
              })
            }
          />
          <InputComponent
            label="Precio"
            htmlfor="precio"
            type="text"
            name="precio"
            value={productoSeleccionado.precio}
            onChange={(e) =>
              setProductoSeleccionado({
                ...productoSeleccionado,
                precio: e.target.value,
              })
            }
          />
        </div>
        <div className="pt-8 relative">
          <label
            className="absolute z-1 px-2 top-7 bg-white text-gray-600"
            htmlFor="descripcion"
          >
            Descripción
          </label>
          <textarea
            className="w-full p-5 rounded border border-solid border-zinc-300 relative"
            name="descripcion"
            value={productoSeleccionado.descripcion}
            onChange={(e) =>
              setProductoSeleccionado({
                ...productoSeleccionado,
                descripcion: e.target.value,
              })
            }
          ></textarea>
        </div>
        <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
         
          <div className="pt-10 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white text-gray-600"
              htmlFor="categoria"
            >
              Seleccione una categoria:
            </label>
            <select
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              id="categoria"
              name="categoria"
              value={productoSeleccionado.categoria}
              onChange={(e) =>
                setProductoSeleccionado({
                  ...productoSeleccionado,
                  categoria: e.target.value,
                })
              }
            >
              <option disabled value="">-- Seleccione--</option>
              {listaCategorias.map((categoria) => (
                <option key={categoria._id} value={categoria._id}>
                  {categoria.nombre}
                </option>
              ))}
            </select>
          </div>
        
           <div className="pt-8 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white text-gray-600"
                htmlFor="medidas"
              >
                Medidas
              </label>
              <textarea
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                name="medidas"
                value={productoSeleccionado.medidas}
                onChange={(e) =>
                  setProductoSeleccionado({
                    ...productoSeleccionado,
                    medidas: e.target.value,
                  })
                }
              ></textarea>
            </div>
        </div>
        <div className="">
          <div className="pt-10 relative">
            <label className="absolute z-1 px-2 top-7 bg-white" htmlFor="usos">
              Seleccione uno o varios usos:
            </label>
            <select
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              id="usos"
              value={usos}
              // Utilizar el valor de 'usos' en lugar de 'productoSeleccionado.usos'
              onChange={(e) =>
                setUsos(
                  Array.from(e.target.selectedOptions, (option) => option.value)
                )
              }
              multiple
            >
              {listaUsos.map((uso) => (
                <option key={uso._id} value={uso._id}>
                  {uso.marca}: {uso.linea} - {uso.modelo}
                </option>
              ))}
            </select>
          </div>
        </div>

        <div className="pt-10 relative">
          <div className="">
            <div className="flex flex-col sm:flex-row space-y-6 space-x-6 mt-10">
              {productoSeleccionado.imagenesPath &&
                productoSeleccionado.imagenesPath.map((imagenPath, index) => (
                
                  <div key={index} className="relative">
                    <img
                      src={`https://tecnipartes-imagenes.nyc3.digitaloceanspaces.com/${imagenPath}`}
                      alt=""
                      className="mx-auto w-40 cursor-pointer"
                    />
                    <button
                      onClick={() => handleEliminarImagen(index)}
                      className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                    >
                      X
                    </button>
                  </div>
                ))}
            </div>
          </div>
          <div className="flex mt-10 ">
            {Array.from(files).map((item, index) => {
              console.log(item, index)
              return (
                <div key={nanoid()} className="relative">
                  <img
                    className="w-40  p-1"
                    src={item ? URL.createObjectURL(item) : null}
                    alt=""
                  />
                  <button
                    onClick={() => eliminarImagenesNuevas(index)}
                    className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                  >
                    X
                  </button>
                </div>
              )
            })}
          </div>
          <label
            className="absolute z-1 px-2 top-7 bg-white"
            htmlFor="imagenes"
          >
            Imagenes
          </label>
          <input
            name="imagenes"
            type="file"
            multiple
            value={productoSeleccionado.imagenes}
            onChange={handleFileChange}
            className="block w-full px-4 py-2 mt-2 text-gray-700 bg-white border border-gray-300 rounded-md  dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
          />
        </div>

        <div className="flex justify-end space-x-2 m-10">
          <Link to="/productos">
            <button className="flex items-center bg-blue-600 hover:bg-blue-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold ">
              Cancelar
            </button>
          </Link>
          <button className="flex items-center bg-green-600 hover:bg-green-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold "
            onClick={() => actualizarProducto()}
          >
            Actualizar Producto
          </button>
        </div>

        <ToastContainer></ToastContainer>
      </div>
    </div>
  )
}

export default EditarProducto
