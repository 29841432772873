import React, { useState, useEffect, useReducer } from "react"
import { nanoid } from "nanoid"
import { Link } from "react-router-dom"
import { toast, ToastContainer } from "react-toastify"
import Busqueda from "../../components/Busqueda";
import { obtenerUsuarios, eliminarUsuario} from "../../utils/apiUsuario";
import ComponentePrivado from "../../components/ComponentePrivado";

const Usuarios = () => {

    const [usuarios, setUsuarios] = useState([]);
    const [reducerValue, forceUpdate] = useReducer((x) => x + 1, 0);
  
    const [busqueda, setBusqueda] = useState("");
    const [usuariosFiltrados, setUsuariosFiltrados] = useState(usuarios);
  
    useEffect(() => {
      const fetchUsuarios= async () => {
        await obtenerUsuarios((response) => {
          setUsuarios(response.data)
        })
      }
  
      fetchUsuarios();
    }, [reducerValue]);
  
    const eliminacionUsuario = async (id) => {
      const response = window.confirm("Esta Seguro de eliminar el Usuario?")
      if (response) {
        await eliminarUsuario(
          id,
          (response) => {
            toast.success("Usuario eliminado con éxito")
            forceUpdate()
          },
          (error) => {
            console.error(error)
            toast.error("Error eliminando el Usuario")
          }
        )
      }
    };
  
    useEffect(() => {
      setUsuariosFiltrados(
        usuarios.filter((elemento) => {
          return JSON.stringify(elemento)
            .toLowerCase()
            .includes(busqueda.toLowerCase())
        })
      )
    }, [busqueda, usuarios]);
    
  
  return (
    <div className="flex h-full w-full flex-col justify-start  bg-zinc-50 ">
    <div className="bg-white h-full mb-3 mt-10 sm:mx-10 shadow shadow-slate-200 rounded pt-10 overflow-y-auto ">
      <div className="flex flex-col w-full">
        <h1 className="text-2xl font-extrabold text-gray-900 pb-10 pl-10 pt-10">
          Listado de Usuarios
        </h1>
      </div>
      <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 bg-zinc-100 m-10 p-10 border border-solid border-zinc-300">
      <Busqueda busqueda={busqueda} setBusqueda={setBusqueda}/>

      </div>

      <div className="relative shadow-md sm:rounded-lg m-10 border border-solid border-zinc-300">
        <table className="w-full text-left text-gray-500 ">
          <thead className="text-gray-700 uppercase  bg-zinc-100 ">
            <tr>
              <th scope="col" className="px-6 py-6">
                Nombre
              </th>

              <th scope="col" className="px-6 py-6">
                email
              </th>
              <th scope="col" className="px-6 py-6">
                Rol
              </th>
              <th scope="col" className="px-6 py-6">
                Imagen
              </th>
              <ComponentePrivado listaRoles={['admin']}>
              <th scope="col" className="px-6 py-6">
                Acciones
              </th>
              </ComponentePrivado>
            </tr>
          </thead>
          <tbody>
            {usuariosFiltrados.map((usuario) => {
              return (
                <tr
                  key={nanoid()}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                >
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    {usuario.nombre}
                  </td>
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    {usuario.email}
                  </td>
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    {usuario.rol}
                  </td>
                  <td className="px-6 py-4  text-gray-600 whitespace-nowrap dark:text-white">
                    <img className="rounded-full h-16" src={usuario.imagen} alt="" />
                  </td>
                  <ComponentePrivado listaRoles={['admin']}>


                  <td className="flex items-center  py-4 space-x-3 justify-center ">
                    <Link to={"/usuario/editar/" + usuario._id}>
                      <i className="fas fa-pencil text-yellow-700 px-3"></i>
                    </Link>

                    <button
                      className="px-3"
                      onClick={() => eliminacionUsuario(usuario._id)}
                    >
                      <i className="fas fa-trash text-red-700"></i>
                    </button>
                  </td>
                  </ComponentePrivado>
                </tr>
              )
            })}
          </tbody>
        </table>
        <ToastContainer />
      </div>
    </div>
  </div>

  )
}

export default Usuarios