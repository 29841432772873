import React from "react";

const InputComponent = ({ label, type, name, value, onChange, required }) => {
  return (
    <div className="pt-8 relative">
      <label className="absolute z-1 px-2 top-7 mb-2 bg-white text-gray-600" htmlFor={name}>
        {label}
      </label>
      <input
        className="w-full p-4 rounded border border-solid border-zinc-300 relative mt-2"
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
       
      />
    </div>
  );
};

export default InputComponent;