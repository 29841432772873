import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useAuth0 } from "@auth0/auth0-react"

import logo from "../media/logoTrans.png"

const Sidebar = () => {
  const { logout, user } = useAuth0()
  const [mostrarNavegacion, setMostrarNavegacion] = useState(false)
  const [open, setOPen] = useState(true)
  const [submenuOpen, setSubmenuOPen] = useState(false)

  const Menus = [
    { title: "Productos", icon: "fa fa-box-archive", ruta: "/productos" },
    {
      title: "Crear Producto",
      icon: "fa-solid fa-circle-plus",
      ruta: "/producto/nuevo",
    },
    {
      title: "Maestras",
      icon: "fa fa-list",
      submenu: true,
      submenuItems: [
        { title: "Categorías", ruta: "/categorias" },
        { title: "Usos", ruta: "/usos" },
      ],
    },
    {
      title: "Usuarios",
      icon: "fa-solid fa-user",
      ruta: "/usuarios",
    },
  ]

  return (
    <div className="">
      <div className="hidden sm:flex h-full">
        {/*SIDEBAR PANTALLAS MAYORES DE 640PX SM*/}
        <div
          className={` bg-secundario shadow flex-col justify-between relative ${
            open ? "w-60" : "w-20"
          } duration-300`}
        >
          <i
            className={`fa-solid fa-arrow-left w-9 h-9 bg-white rounded shadow px-2 py-1  -right-3 top-32 text-principal  text-xl absolute border border-principal cursor-pointer ${
              !open && "rotate-180"
            }`}
            onClick={() => setOPen(!open)}
          ></i>
          <div className="flex pt-5 items-center ">
            <img
              src={logo}
              alt="tecnipartes"
              className={`duration-300 ${open ? "p-12" : "scale-90"}`}
            />
          </div>
          <ul className={`pt-2 ${!open && "pt-36"}`}>
            {Menus.map((menu, index) => (
              <>
                <Link to={menu.ruta} key={index}>
                  <li
                    className={`${
                      menu.spacing ? "mt-36" : "mt-2"
                    } text-white text-sm flex items-center gap-x-4  cursor-pointer p-2 pl-8 hover:bg-principal rounded-md mt-2`}
                  >
                    <span className=" block float-left">
                      <i className={`${menu.icon}`}></i>
                    </span>
                    <span
                      className={`text-base flex-1 ${
                        !open && "hidden"
                      } duration-300`}
                    >
                      {menu.title}
                    </span>
                    {menu.submenu && open && (
                      <i
                        className={`${
                          submenuOpen && "rotate-180"
                        } fa fa-chevron-down p-3`}
                        onClick={() => setSubmenuOPen(!submenuOpen)}
                      ></i>
                    )}
                  </li>
                </Link>
                {menu.submenu && submenuOpen && open && (
                  <ul>
                    {menu.submenuItems.map((submenuItem, index) => (
                      <Link to={submenuItem.ruta} key={index}>
                        <li className="text-white  text-sm flex items-center gap-x-4  cursor-pointer p-2 pl-14 hover:bg-principal rounded-md mt-2">
                          {submenuItem.title}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
              </>
            ))}
          </ul>

          <div className="  text-white text-sm ">
            <div className=" text-white text-sm flex items-center gap-x-4  cursor-pointer p-2 pl-8 hover:bg-principal rounded-md mt-2">
              <i className="block float-left fas fa-arrow-right-from-bracket"></i>
              <button
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                <span className={` ${!open && "hidden"} duration-300`}>
                  {" "}
                  Cerrar Sesión
                </span>
              </button>
            </div>

            <div>
              <div className="flex flex-col text-white text-sm items-center gap-x-4  cursor-pointer p-2 hover:bg-principal rounded-md mt-20">
                <img src={user.picture} className="h-9 w-9 rounded-full" />
                <span
                  className={`text-sm flex-1 ${
                    !open && "hidden"
                  } duration-300 mt-2`}
                >
                  {user.name}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*NAVEGACION PANTALLAS MENORES DE 640PX SM*/}
      <div className="sm:hidden w-screen block bg-secundario">
        <div className="flex items-center w-full ">
          <i
            className={`mx-4 fa-solid fa-${
              mostrarNavegacion ? "xmark" : "bars"
            } hover:text-principal text-white`}
            onClick={() => {
              setMostrarNavegacion(!mostrarNavegacion)
            }}
          />
          <div className="flex w-full justify-center pt-4 pb-4">
            <img className="h-16" src={logo} alt="tecnipartes" />
          </div>
        </div>
        {mostrarNavegacion && (
          <ul className={`pt-2 ${!open && "pt-8"}`}>
            {Menus.map((menu, index) => (
              <>
                <Link to={menu.ruta} key={index}>
                  <li className=" text-white text-sm flex items-center gap-x-4  cursor-pointer p-2 pl-8 hover:bg-principal rounded-md mt-2">
                    <span className=" block float-left">
                      <i className={`${menu.icon}`}></i>
                    </span>
                    <span
                      className="text-xs font-medium flex-1 
                      duration-300"
                    >
                      {menu.title}
                    </span>
                    {menu.submenu && (
                      <i
                        className={`${
                          submenuOpen && "rotate-180"
                        } fa fa-chevron-down`}
                        onClick={() => setSubmenuOPen(!submenuOpen)}
                      ></i>
                    )}
                  </li>
                </Link>
                {menu.submenu && submenuOpen && (
                  <ul>
                    {menu.submenuItems.map((submenuItem, index) => (
                      <Link to={submenuItem.ruta} key={index}>
                        <li className="text-white  text-xs flex items-center gap-x-4  cursor-pointer p-2 pl-14 hover:bg-principal rounded-md mt-2">
                          {submenuItem.title}
                        </li>
                      </Link>
                    ))}
                  </ul>
                )}
              </>
            ))}
            <div className="text-white text-xs flex items-center gap-x-4  cursor-pointer p-2 pl-8 hover:bg-principal rounded-md mt-2 ">
              <i className="fas fa-arrow-right-from-bracket"></i>
              <button
                className="mb-2"
                onClick={() =>
                  logout({ logoutParams: { returnTo: window.location.origin } })
                }
              >
                Cerrar Sesión
              </button>
            </div>
          </ul>
        )}
      </div>
    </div>
  )
}

export default Sidebar
