import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { editarUso, obtenerUso } from "../../utils/apiUsos"

const EditarUso = () => {
  const [usoSeleccionado, setUsoSeleccionado] = useState({})

  const idUso = useParams().id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUso = async () => {
      await obtenerUso(`${idUso}`, (response) => {
        setUsoSeleccionado(response.data)
      })
    }

    fetchUso();
  }, [idUso]);

  const actualizarUso = async () => {
    await editarUso(
      idUso,
      {
        marca: usoSeleccionado.marca,
        linea: usoSeleccionado.linea,
        modelo: usoSeleccionado.modelo
      },
      (response) => {
        console.log(response.data)
        toast.success("Uso modificado con éxito")
        setTimeout(() => {
          navigate("/usos")
        }, 1500)
      },
      (error) => {
        toast.error("Error modificando el uso")
        console.error(error)
      }
    )
  };

  return (
    <div className="flex w-full flex-col justify-start bg-zinc-50">
      <div className="mx-auto w-9/12 px-36 pt-24 pb-18 bg-white mb-3 mt-10 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-3xl font-extrabold text-gray-900 pb-10">
            Editar Uso
          </h1>
        </div>
        <h2 className="p-6 border border-solid border-b-principal bg-zinc-100">
          Información General
        </h2>
      
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white"
                htmlFor="marca"
              >
                Marca
              </label>
              <input
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                type="text"
                name="marca"
                required
                defaultValue={usoSeleccionado.marca}
                onChange={(e) =>
                  setUsoSeleccionado({
                    ...usoSeleccionado,
                    marca: e.target.value,
                  })
                }
              />
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white"
                htmlFor="linea"
              >
                Linea
              </label>
              <input
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                type="text"
                name="linea"
                defaultValue={usoSeleccionado.linea}
                onChange={(e) =>
                  setUsoSeleccionado({
                    ...usoSeleccionado,
                    linea: e.target.value,
                  })
                }
              />
            </div>
            <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white"
                htmlFor="modelo"
              >
                Modelo
              </label>
              <input
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                type="text"
                name="modelo"
                defaultValue={usoSeleccionado.modelo}
                onChange={(e) =>
                  setUsoSeleccionado({
                    ...usoSeleccionado,
                    modelo: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2 m-10">
            <Link to="/usos">
            <button  className="px-3 flex items-center bg-blue-600 hover:bg-blue-500  rounded sm:px-6 py-3 text-base  text-white font-semibold">
                Cancelar
              </button>
            </Link>
            <button
              onClick={() => actualizarUso()}
              className="px-3 flex items-center bg-green-600 hover:bg-green-500  rounded sm:px-6 py-3 text-base  text-white font-semibold"
            >
              Editar Uso
            </button>
          </div>
       
        <ToastContainer></ToastContainer>
      </div>
    </div>
  )
}

export default EditarUso
