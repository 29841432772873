import { BrowserRouter, Routes, Route } from "react-router-dom"
import { Auth0Provider } from "@auth0/auth0-react"

import PrivateLayout from "./layouts/PrivateLayout"
import Productos from "./pages/productos/Productos"
import Producto from "./pages/productos/Producto"
import NuevoProducto from "./pages/productos/NuevoProducto"
import EditarProducto from "./pages/productos/EditarProducto"
import Categorias from "./pages/categorias/Categorias"
import NuevaCategoria from "./pages/categorias/NuevaCategoria"
import EditarCategoria from "./pages/categorias/EditarCategoria"
import Usos from "./pages/usos/Usos"
import NuevoUso from "./pages/usos/NuevoUso"
import EditarUso from "./pages/usos/EditarUso"
import Usuarios from "./pages/usuarios/Usuarios"
import EditarUsuario from "./pages/usuarios/EditarUsuario"
import { UserContext } from "./contextos/userContext"
import { useState } from "react"
import RutasPrivadas from "./components/RutasPrivadas"

function App() {

  const [userData, setUserData] = useState({});
  const redirectUrl = process.env.NODE_ENV === 'development'
  ? 'http://localhost:3000/productos'
  : 'https://tecnipartestulua.com.co/productos';


  return (
    <Auth0Provider
      domain="tecnipartes-desarrollo.us.auth0.com"
      clientId="n0K6WeHWaDU6fR9V0m5Fd9zkisMGEJxr"
      authorizationParams={{
        redirect_uri: redirectUrl,
        audience: "api-autenticacion-tecnipartes",
      }}
    >
      <UserContext.Provider value={{ userData, setUserData }}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateLayout />}>
              <Route path="/" element={<Productos />} />
              <Route path="productos" element={<Productos />} />
              <Route path="producto/nuevo" element={<NuevoProducto />} />
         
              <Route path="producto/editar/:id" element={<RutasPrivadas listaRoles={['admin']}><EditarProducto /></RutasPrivadas>} />

          
              <Route path="producto/:id" element={<Producto />} />
              <Route path="categorias" element={<Categorias />} />
              <Route path="categoria/nueva" element={<NuevaCategoria />} />
              <Route
                path="categoria/editar/:id"
                element={<RutasPrivadas listaRoles={['admin']}><EditarCategoria/></RutasPrivadas>} />
              
              <Route path="usos" element={<Usos />} />
              <Route path="uso/nuevo" element={<NuevoUso />} />
              <Route path="uso/editar/:id" element={<RutasPrivadas listaRoles={['admin']}><EditarUso /></RutasPrivadas>} />
              <Route path="usuarios" element={<Usuarios />} />
              <Route path="usuario/editar/:id"  element={<RutasPrivadas listaRoles={['admin']}><EditarUsuario/></RutasPrivadas>} />
            </Route>
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </Auth0Provider>
  )
}

export default App
