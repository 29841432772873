import React, { useState, useEffect } from "react"

import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import { nanoid } from "nanoid"
import { useNavigate, Link } from "react-router-dom"
import { format } from "date-fns"

import { obtenerCategorias } from "../../utils/apiCategoria"
import { obtenerUsos } from "../../utils/apiUsos"
import InputComponent from "../../components/InputComponent"

const NuevoProducto = () => {
  const [nombre, setNombre] = useState("")
  const [file, setFile] = useState("")
  const [referencia, setReferencia] = useState("")
  const [costo, setCosto] = useState("")
  const [precio, setPrecio] = useState("")
  const [medidas, setMedidas] = useState("")
  const [descripcion, setDescripcion] = useState("")
  const [fechaIngreso, setFechaIngreso] = useState(
    format(new Date(), "yyyy-MM-dd")
  )
  const [usos, setUsos] = useState([])
  const [categoria, setCategoria] = useState("")
  const [listaCategorias, setListaCategorias] = useState([])
  const [listaUsos, setListaUsos] = useState([])


  const getToken = () => {
    return `Bearer ${localStorage.getItem('token')}`
    
  };

  useEffect(() => {
    const fetchCategorias = async () => {
      await obtenerCategorias((response) => {
        setListaCategorias(response.data)
      })
    }

    fetchCategorias()
  }, [])

  useEffect(() => {
    const fetchUsos = async () => {
      await obtenerUsos((response) => {
        setListaUsos(response.data)
        console.log(response.data)
      })
    }

    fetchUsos()
  }, [])

  const navigate = useNavigate()

  const valorUsos = (e) => {
    const selectedOptions = Array.from(e.target.selectedOptions)

    const selectedIds = selectedOptions.map((option) => option.value)

    setUsos([...selectedIds])
  }

  const setimgfile = (e) => {
    setFile(e.target.files)
  }

  const eliminarImagenesNuevas = (index) => {
    const updatedFiles = [...file]
    updatedFiles.splice(index, 1)
    setFile(updatedFiles)
  }

  const submitForm = async (e) => {
    e.preventDefault()

    // Definir mensajes de error para cada campo
    const errorMessages = {
      nombre: "Por favor ingrese el nombre",
      costo: "Por favor ingrese el costo",
      precio: "Por favor ingrese el precio",
      // Agrega más mensajes de error para otros campos si es necesario
    }

    // Validar campos y mostrar mensajes de error
    if (!nombre) {
      toast.error(errorMessages.nombre)
      return
    }
    if (!costo) {
      toast.error(errorMessages.costo)
      return
    }
    if (!precio) {
      toast.error(errorMessages.precio)
      return
    }

    const fd = new FormData()

    fd.append("nombre", nombre)
    fd.append("referencia", referencia)
    fd.append("costo", costo)
    fd.append("precio", precio)
    fd.append("medidas", medidas)
    fd.append("fechaIngreso", fechaIngreso)
    fd.append("categoria", categoria)
    fd.append("descripcion", descripcion)

    for (let i = 0; i < usos.length; i++) {
      fd.append("usos[]", usos[i])
    }

    for (let i = 0; i < file.length; i++) {
      fd.append("imagenes", file[i])
    }

    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: getToken()
      },
    }

    const url = "https://tecnipartes-8lvni.ondigitalocean.app/api/product"
    axios
      .post(url, fd, config)
      .then((result) => {
        console.log(result)
        toast.success("Producto Agregado con Éxito")
        setTimeout(() => {
          navigate("/productos")
        }, 1000)
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="flex w-full flex-col h-full justify-start bg-zinc-50 overflow-y-auto">
      <div className="mx-auto w-10/12 px-36 pt-24 pb-18 bg-white mb-3 mt-2 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-2xl font-extrabold text-gray-900 pb-6">
            Crear Nuevo Producto
          </h1>
        </div>

        <form className="">
          <div>
            <InputComponent
              label="Nombre*"
              htmlFor="nombre"
              type="text"
              name="nombre"
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-1 gap-6 mt-2 sm:grid-cols-2">
            <InputComponent
              label="Referencia"
              htmlFor="referencia"
              type="text"
              name="referencia"
              value={referencia}
              onChange={(e) => setReferencia(e.target.value)}
            />
             <InputComponent
              label="Fecha de Ingreso"
              htmlFor="fechaIngreso"
              type="date"
              name="fechaIngreso"
              value={fechaIngreso}
              onChange={(e) => setFechaIngreso(e.target.value)}
            />
          
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <InputComponent
              label="Costo*"
              htmlFor="costo"
              type="number"
              name="costo"
              value={costo}
              onChange={(e) => setCosto(e.target.value)}
            />
            <InputComponent
              label="Precio*"
              htmlFor="precio"
              type="number"
              name="precio"
              value={precio}
              onChange={(e) => setPrecio(e.target.value)}
            />
          </div>
          <div className="pt-8 relative">
            <label
              className="absolute z-1 px-2 top-7 bg-white text-gray-600"
              htmlFor="descripcion"
            >
              Descripción
            </label>
            <textarea
              className="w-full p-5 rounded border border-solid border-zinc-300 relative"
              name="descripcion"
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            ></textarea>
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
           
            <div className="pt-8 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white text-gray-600"
                htmlFor="categoria"
              >
                Seleccione una categoria:
              </label>
              <select
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                id="categoria"
                value={categoria}
                onChange={(e) => setCategoria(e.target.value)}
              >
                <option disabled value="">-- Seleccione--</option>
                {listaCategorias.map((categoria) => (
                  <option key={nanoid()} value={categoria.value}>
                    {categoria.nombre}
                  </option>
                ))}
              </select>
            </div>
            <div className="pt-8 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white text-gray-600"
                htmlFor="medidas"
              >
                Medidas
              </label>
              <textarea
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                name="medidas"
                value={medidas}
                onChange={(e) => setMedidas(e.target.value)}
              ></textarea>
            </div>
          </div>
          <div className="">
            <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white text-gray-600"
                htmlFor="usos"
              >
                Seleccione uno o varios usos:
              </label>
              <select
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                id="usos"
                value={usos}
                onChange={valorUsos}
                multiple
              >
                {listaUsos.map((uso) => (
                  <option key={nanoid()} value={uso._id}>
                    {uso.marca}: {uso.linea} - {uso.modelo}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="pt-10 relative">
            <div className="flex ">
              <ul className="flex space-x-4 mt-4">
                {Array.from(file).map((item, index) => (
                  <li key={nanoid()} className="relative">
                    <img
                      className="w-60 h-60 p-1"
                      src={item ? URL.createObjectURL(item) : null}
                      alt=""
                    />
                    <button
                      onClick={() => eliminarImagenesNuevas(index)}
                      className="absolute top-2 right-2 bg-red-500 text-white px-2 py-1 rounded"
                    >
                      X
                    </button>
                  </li>
                ))}
              </ul>
            </div>
            <label
              className="absolute z-1 px-2 top-7 bg-white text-gray-600"
              htmlFor="imagenes"
            >
              Imagenes
            </label>
            <input
              name="imagenes"
              type="file"
              multiple
              onChange={setimgfile}
              className="block w-full px-4 py-2 mt-2 mb-4 text-gray-700 bg-white border border-gray-300 rounded-md  dark:text-gray-300 dark:border-gray-600 focus:border-blue-500 dark:focus:border-blue-500 focus:outline-none focus:ring"
            />
          </div>
          <span className="">Los campos marcados con * son obligatorios </span>

          <div className="flex justify-end space-x-2 m-10">
            <Link to="/productos">
              <button className="flex items-center bg-blue-600 hover:bg-blue-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold">
                Cancelar
              </button>
            </Link>
            <button
              type="submit"
              onClick={submitForm}
              className="flex items-center bg-green-600 hover:bg-green-500 rounded px-4 py-2 text-base sm:text-l text-white font-semibold"
            >
              Crear Producto
            </button>
          </div>
        </form>

        <ToastContainer></ToastContainer>
      </div>
    </div>
  )
}

export default NuevoProducto
