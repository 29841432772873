import React from "react";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { editarUsuario, obtenerUsuario } from "../../utils/apiUsuario";

const EditarUsuario = () => {
  const [usuarioSeleccionado, setUsuarioSeleccionado] = useState({});
 

  const idUsuario = useParams().id;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUso = async () => {
      await obtenerUsuario(`${idUsuario}`, (response) => {
        setUsuarioSeleccionado(response.data)
      })
    }

    fetchUso();
  }, [idUsuario]);

  const actualizarUsuario = async () => {
    await editarUsuario(
      idUsuario,
      {
        nombre: usuarioSeleccionado.nombre,
       // imagen: usuarioSeleccionado.imagen,
        rol: usuarioSeleccionado.rol,
        estado: usuarioSeleccionado.estado
      },
      (response) => {
        console.log(response.data)
        toast.success("Usuario modificado con éxito")
        setTimeout(() => {
          navigate("/usuarios")
        }, 1500)
      },
      (error) => {
        toast.error("Error modificando el usuario")
        console.error(error)
      }
    )
  };

  return (
    <div className="flex w-full flex-col justify-start bg-zinc-50">
      <div className="mx-auto w-9/12 px-36 pt-24 pb-18 bg-white mb-3 mt-10 shadow shadow-slate-200 rounded-2xl">
        <div className="flex flex-col w-full">
          <h1 className="text-3xl font-extrabold text-gray-900 pb-10">
            Editar Usuario
          </h1>
        </div>
        <h2 className="p-6 border border-solid border-b-principal bg-zinc-100">
          Información General
        </h2>
      
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
            <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white"
                htmlFor="nombre"
              >
                Nombre
              </label>
              <input
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                type="text"
                name="nombre"
                required
                defaultValue={usuarioSeleccionado.nombre}
                onChange={(e) =>
                  setUsuarioSeleccionado({
                    ...usuarioSeleccionado,
                    nombre: e.target.value,
                  })
                }
              />
            </div>
            {/* <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white"
                htmlFor="rol"
              >
                Rol
              </label>
              <input
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                type="text"
                name="rol"
                defaultValue={usuarioSeleccionado.rol}
                onChange={(e) =>
                  setUsuarioSeleccionado({
                    ...usuarioSeleccionado,
                    rol: e.target.value,
                  })
                }
              />
            </div> */}
            <div className="pt-8 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white text-gray-600"
                htmlFor="rol"
              >
                Seleccione un rol:
              </label>
              <select
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                id="rol"
                value={usuarioSeleccionado.rol}
                onChange={(e) =>
                  setUsuarioSeleccionado({
                    ...usuarioSeleccionado,
                    rol: e.target.value,
                  })
                }
              >
                <option disabled>-- Seleccione un Rol --</option>
                <option value="admin">Administrador</option>
                <option value="vendedor">Vendedor</option>
               
              </select>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-6 mt-4 sm:grid-cols-2">
           
            <div className="pt-10 relative">
              <label
                className="absolute z-1 px-2 top-7 bg-white"
                htmlFor="estado"
              >
                Estado
              </label>
              <input
                className="w-full p-5 rounded border border-solid border-zinc-300 relative"
                type="text"
                name="estado"
                defaultValue={usuarioSeleccionado.estado}
                onChange={(e) =>
                  setUsuarioSeleccionado({
                    ...usuarioSeleccionado,
                    estado: e.target.value,
                  })
                }
              />
            </div>
          </div>

          <div className="flex justify-end space-x-2 m-10">
            <Link to="/usuarios">
            <button  className="px-3 flex items-center bg-blue-600 hover:bg-blue-500  rounded sm:px-6 py-3 text-base  text-white font-semibold">
                Cancelar
              </button>
            </Link>
            <button
              onClick={() => actualizarUsuario()}
              className="px-3 flex items-center bg-green-600 hover:bg-green-500  rounded sm:px-6 py-3 text-base  text-white font-semibold"
            >
              Editar Usuario
            </button>
          </div>
       
        <ToastContainer/>
      </div>
    </div>
  )
}

export default EditarUsuario
